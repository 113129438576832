/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom'

import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';

import { toast } from 'react-toastify';

import PaymentForm from '../components/paymentsTab/PaymentForm';
import RemitterForm from '../components/paymentsTab/RemitterForm';
import UserContext from '../../../context/UserContext';
import CreditCardModal from '../components/paymentsTab/CardFormModal';
import OpenBatchModal from '../components/batches/OpenBatchModal';
import { ThemeModeContext, apiRoute } from '../../../App';
import { CaseContext } from '../../../context/CaseContext';
import { BatchContext } from '../../../context/BatchMgmtContext';
import { commaMoneyFormat, formatMoney } from '../../../Utils';

const PaymentModals = (props) => {
    const {
        handleClose,
        violationBalance,
        openBatches,
        violationView,
        updateCaseBalance,
        violation,
        setViolation,
        modalType,
        ts,
        loading,
        setLoading,
        selectedPayment,
        newBatch,
        handleOpen,
        receiptNumber,
        setReceiptNumber,
        citationState,
        retrieveReceiptPayment,
        setRetrieveReceiptPayment,
        overridedPartialPayment,
        userName
    } = props;

    const {
        paymentOptions,
        setCaseHistoryRows,
        caseHistoryRows,
        setBalance,
        violationRows,
        setViolationRows,
        chargesRows,
        setChargesRows,
        balance
    } = useContext(CaseContext);
    // Initialize state for checkbox checked status
    const [checkedItems, setCheckedItems] = useState([]);
    const [pkViolations, setPkViolations] = useState([]);
    const [selectedStatuteDesc, setSelectedStatuteDesc] = useState('');


    let calculatedViolationBalance = modalType === 'quickPayment' ? violationRows.reduce((sum, violation) => sum + violation.balance, 0) : violationBalance;

    const { entityId, userId, convenienceFeeRate, useFeeSchedule } = useContext(UserContext);
    const { setOpenBatches } = useContext(BatchContext);

    const { mode } = useContext(ThemeModeContext);
    const { caseNumber } = useParams();
    // null variable so the open batch modal can be called
    const selectedRow = {};

    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailCheck, setEmailCheck] = useState(false);
    const [printCheck, setPrintCheck] = useState(false);
    const [textCheck, setTextCheck] = useState(false);
    const [receiptError, setReceiptError] = useState({});

    // QUICK PAYMENT CHECK FOR CREDIT CARD PAYMENT MODAL
    const [quickCheck, setQuickCheck] = useState(false);
    const [noViolation, setNoViolation] = useState('');

    // STATE FOR NEW REMAINING BALANCE BASED ON PAYMENT AMOUNT FOR QUICK PAYMENTS
    const [newCaseBalance, setNewCaseBalance] = useState(balance);

    // EDIT PAYMENT TYPE STATES
    const [paymentType, setPaymentType] = useState(selectedPayment.paymentType);
    const [checkNumber, setCheckNumber] = useState(selectedPayment.checkNumber || "");

    // GETTING DISPLAY BALANCE FOR PAYMENT FORMS
    const [displayBalance, setDisplayBalance] = useState(violationBalance);
    const [convenienceRate, setConvenienceRate] = useState(convenienceFeeRate)
    const [convenienceFee, setConvenienceFee] = useState((displayBalance * convenienceRate).toFixed(2))
    const [grandTotal, setGrandTotal] = useState(0.00)
    const [currentCaseBalance, setCurrentCaseBalance] = useState(0);

    // REFUND AND REVERSE PAYMENT STATES
    const [notes, setNotes] = useState('');

    // OPEN BATCH STATES
    const [batchType, setBatchType] = useState('SIMPLE');
    const [batchDate, setBatchDate] = useState(dayjs(''));

    const [ccPaymentAmount, setCcPaymentAmount] = useState(0);

    const [masterReceipt, setMasterReceipt] = useState(0);
    const [batchName, setBatchName] = useState(`Batch ${userName}`);
    const [grand, setGrand] = useState();
    const [change, setChange] = useState(0.00);
    const [totalPayment, setTotalPayment] = useState(0.00);
    const [updatePaymentBatchNumber, setUpdatePaymentBatchNumber] = useState('0');

    // USEREDUCER FORM DATA FOR REMITTER FORM FOR CREATING PAYMENTS
    const initialRemitterState = {
        payToName: modalType === 'refundPayment' ? selectedPayment.payToName : '',
        payToAddress1: modalType === 'refundPayment' ? selectedPayment.payToAddress1 : '',
        payToAddress2: modalType === 'refundPayment' ? selectedPayment.payToAddress2 || '' : '',
        payToCity: modalType === 'refundPayment' ? selectedPayment.payToCity : '',
        payToState: modalType === 'refundPayment' ? selectedPayment.payToState : '',
        payToZip: modalType === 'refundPayment' ? selectedPayment.payToZip : '',
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialRemitterState);

    // EDIT PAYMENT TYPE FUNCTION
    const editPaymentType = async () => {
        setLoading(true);
        try {
            await axios.put(`${apiRoute}/api/ffpaymenttbls/${selectedPayment.pkFfPaymentId}`, {
                paymentType: paymentType,
                checkNumber: checkNumber
            })
            // Update the payment type in the local state
            const updatedRows = caseHistoryRows.map((row) => {
                if (row.id === selectedPayment.pkFfPaymentId) {
                    return { ...row, paymentType: paymentType };
                }
                return row;
            });
            setCaseHistoryRows(updatedRows);
            handleClose();
            toast.success('Payment type change successful.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (err) {
            toast.error('An error occurred while updating the payment type.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const balanceDue = checkedItems.reduce((total, item) => total + item.remainingBalance, 0);
        setCurrentCaseBalance(balanceDue);
    }, [checkedItems])


    // REFUND PAYMENT FUNCTION
    const refundPayment = async () => {
        setLoading(true);
        let paymentNotes;
        if (notes === '') {
            paymentNotes = null
        } else {
            paymentNotes = notes;
        }
        try {
            const res = await axios.post(`${apiRoute}/api/ffpaymenttbls/refund`, { paymentId: selectedPayment.pkFfPaymentId, notes: paymentNotes, batchNumber: updatePaymentBatchNumber },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            try {

                const newRefund = {
                    ...res.data.refundPaymentRecord,
                    id: res.data.refundPaymentRecord.pkFfPaymentId,
                    paymentDate: res.data.refundPaymentRecord.paymentDate ? new Date(res.data.refundPaymentRecord.paymentDate).toISOString().split('T')[0] : '',
                    dateEnter: res.data.refundPaymentRecord.dateEnter ? new Date(res.data.refundPaymentRecord.dateEnter).toISOString().split('T')[0] : '',
                };
                const editedPayment = {
                    ...res.data.existingPayment,
                    id: res.data.existingPayment.pkFfPaymentId,
                    refundReason: 'REFUND',
                    paymentDate: res.data.existingPayment.paymentDate ? new Date(res.data.existingPayment.paymentDate).toISOString().split('T')[0] : '',
                    dateEnter: res.data.existingPayment.dateEnter ? new Date(res.data.existingPayment.dateEnter).toISOString().split('T')[0] : '',
                };
                // get the violationId attached to the payment
                const violationId = res.data.existingPayment.fkViolationId

                // Find the index of the row to update
                const rowIndex = caseHistoryRows?.findIndex(({ id }) => id === editedPayment.id);

                if (rowIndex !== -1) {
                    // Create a new array with the updated row
                    const updatedRows = [
                        ...caseHistoryRows.slice(0, rowIndex),
                        editedPayment,
                        ...caseHistoryRows.slice(rowIndex + 1)
                    ];

                    // Update the state
                    setCaseHistoryRows([...updatedRows, newRefund]);
                };

                // Find the specific violation in violationRows
                const updatedViolationIndex = violationRows?.findIndex(({ pkViolationId }) => pkViolationId === violationId);

                if (updatedViolationIndex !== -1) {

                    //Find the row to adjust, make sure convenience fee is accounted for
                    const updatedViolationRows = [...violationRows];
                    updatedViolationRows[updatedViolationIndex].remainingBalance -= (res.data.refundPaymentRecord.paymentAmount - res.data.refundPaymentRecord.convenienceFee);
                    setViolationRows(updatedViolationRows);

                    // Filtering vio rows based on the remaining balance to keep unwanted charges from seeping into charges box
                    setChargesRows(updatedViolationRows.filter(row => row.remainingBalance > 0.00).map(row => row));

                    // Calculate total remaining balance
                    const totalRemainingBalance = updatedViolationRows.reduce((total, violation) => total + violation.remainingBalance, 0);

                    // Update the balance state with the total remaining balance
                    setBalance(totalRemainingBalance);
                }
                toast.success(`Payment Refunded.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleClose();
                setUpdatePaymentBatchNumber('0');
            } catch (err) {
                console.log(err);
                toast.success(`Error Refunding Payment. ${err.message}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (err) {
            console.log('err refunding payment: ', err);
        } finally {
            setLoading(false);
        }
    };

    // REVERSE PAYMENT FUNCTION
    const reversePayment = async () => {
        setLoading(true);
        let paymentNotes;
        if (notes === '') {
            paymentNotes = null
        } else {
            paymentNotes = notes;
        }
        try {
            const res = await axios.post(`${apiRoute}/api/ffpaymenttbls/reversal`, { paymentId: selectedPayment.pkFfPaymentId, notes: paymentNotes, batchNumber: updatePaymentBatchNumber },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            try {
                const newRefund = {
                    ...res.data.reversalPaymentRecord,
                    id: res.data.reversalPaymentRecord.pkFfPaymentId,
                    paymentDate: res.data.reversalPaymentRecord.paymentDate ? new Date(res.data.reversalPaymentRecord.paymentDate).toISOString().split('T')[0] : '',
                    dateEnter: res.data.reversalPaymentRecord.dateEnter ? new Date(res.data.reversalPaymentRecord.dateEnter).toISOString().split('T')[0] : '',
                };
                const editedPayment = {
                    ...res.data.existingPayment,
                    id: res.data.existingPayment.pkFfPaymentId,
                    refundReason: 'REVERSAL',
                    paymentDate: res.data.existingPayment.paymentDate ? new Date(res.data.existingPayment.paymentDate).toISOString().split('T')[0] : '',
                    dateEnter: res.data.existingPayment.dateEnter ? new Date(res.data.existingPayment.dateEnter).toISOString().split('T')[0] : '',
                };
                // get the violationId attached to the payment
                const violationId = res.data.existingPayment.fkViolationId

                // Find the index of the row to update
                const rowIndex = caseHistoryRows.findIndex(row => row.id === editedPayment.id);

                if (rowIndex !== -1) {
                    // Create a new array with the updated row
                    const updatedRows = [
                        ...caseHistoryRows.slice(0, rowIndex),
                        editedPayment,
                        ...caseHistoryRows.slice(rowIndex + 1)
                    ];

                    // Update the state
                    setCaseHistoryRows([...updatedRows, newRefund]);
                };

                // Find the specific violation in violationRows
                const updatedViolationIndex = violationRows.findIndex(vio => vio.pkViolationId === violationId);

                if (updatedViolationIndex !== -1) {

                    //Find the row to adjust, make sure convenience fee is accounted for
                    const updatedViolationRows = [...violationRows];
                    updatedViolationRows[updatedViolationIndex].remainingBalance -= (res.data.reversalPaymentRecord.paymentAmount - res.data.reversalPaymentRecord.convenienceFee);
                    setViolationRows(updatedViolationRows);

                    // Filtering vio rows based on the remaining balance to keep unwanted charges from seeping into charges box
                    setChargesRows(updatedViolationRows.filter(row => row.remainingBalance > 0.00).map(row => row));

                    // Calculate total remaining balance
                    const totalRemainingBalance = updatedViolationRows.reduce((total, violation) => total + violation.remainingBalance, 0);

                    // Update the balance state with the total remaining balance
                    setBalance(totalRemainingBalance);
                }

                toast.success(`Payment Reversed.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleClose();
                setUpdatePaymentBatchNumber('0');
            } catch (err) {
                console.log(err);
                toast.success(`Error Reversing Payment. ${err.message}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (err) {
            console.log('err reversing payment: ', err);
        } finally {
            setLoading(false);
        }
    };

    const voidPayment = async () => {
        setLoading(true);
        let paymentNotes;
        if (notes === '') {
            paymentNotes = ""
        } else {
            paymentNotes = notes;
        }

        try {
            const res = await axios.post(`${apiRoute}/api/ffpaymenttbls/void/addVoidedPayment`, { paymentId: selectedPayment.pkFfPaymentId, notes: paymentNotes, batchNumber: updatePaymentBatchNumber },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            try {
                const voidPayment = {
                    ...res.data.voidPaymentRecord,
                    id: res.data.voidPaymentRecord.pkFfPaymentId,
                    paymentDate: res.data.voidPaymentRecord.paymentDate ? new Date(res.data.voidPaymentRecord.paymentDate).toISOString().split('T')[0] : '',
                    dateEnter: res.data.voidPaymentRecord.dateEnter ? new Date(res.data.voidPaymentRecord.dateEnter).toISOString().split('T')[0] : '',
                };
                const editedPayment = {
                    ...res.data.existingPayment,
                    id: res.data.existingPayment.pkFfPaymentId,
                    refundReason: 'VOID',
                    paymentDate: res.data.existingPayment.paymentDate ? new Date(res.data.existingPayment.paymentDate).toISOString().split('T')[0] : '',
                    dateEnter: res.data.existingPayment.dateEnter ? new Date(res.data.existingPayment.dateEnter).toISOString().split('T')[0] : '',
                };
                // get the violationId attached to the payment
                const violationId = res.data.existingPayment.fkViolationId

                // Find the index of the row to update
                const rowIndex = caseHistoryRows?.findIndex(({ id }) => id === editedPayment.id);

                if (rowIndex !== -1) {
                    // Create a new array with the updated row
                    const updatedRows = [
                        ...caseHistoryRows.slice(0, rowIndex),
                        editedPayment,
                        ...caseHistoryRows.slice(rowIndex + 1)
                    ];

                    // Update the state
                    setCaseHistoryRows([...updatedRows, voidPayment]);
                };

                // Find the specific violation in violationRows
                const updatedViolationIndex = violationRows?.findIndex(({ pkViolationId }) => pkViolationId === violationId);

                if (updatedViolationIndex !== -1) {

                    //Find the row to adjust, make sure convenience fee is accounted for
                    const updatedViolationRows = [...violationRows];
                    updatedViolationRows[updatedViolationIndex].remainingBalance -= (res.data.voidPaymentRecord.paymentAmount - res.data.voidPaymentRecord.convenienceFee);
                    setViolationRows(updatedViolationRows);

                    // Filtering vio rows based on the remaining balance to keep unwanted charges from seeping into charges box
                    setChargesRows(updatedViolationRows.filter(row => row.remainingBalance > 0.00).map(row => row));

                    // Calculate total remaining balance
                    const totalRemainingBalance = updatedViolationRows.reduce((total, violation) => total + violation.remainingBalance, 0);

                    // Update the balance state with the total remaining balance
                    setBalance(totalRemainingBalance);
                }
                toast.success(`Payment Voided.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleClose();
                setUpdatePaymentBatchNumber('0');
            } catch (err) {
                console.log(err);
                toast.success(`Error Voiding Payment. ${err.message}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (err) {
            console.log('err voiding payment: ', err);
        } finally {
            setLoading(false);
        }
    };

    const handleBatchOpen = async () => {
        let dayjsBatchDate = dayjs(batchDate);
        const { $D } = dayjsBatchDate;
        if (isNaN($D)) {
            toast.error(`Select a date to open a new batch.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {

            const batchData = {
                fkUserId: userId,
                entityId: entityId,
                openDatetime: dayjsBatchDate.toISOString().split('T')[0],
                BatchType: batchType,
                batchName: batchName
            }

            try {
                const res = await axios.post(`${apiRoute}/api/batchtbl`, batchData);
                setOpenBatches([...openBatches, res.data]);
                toast.success(`Batch ${batchName} (${res?.data?.batchNumber}) opened.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleClose();
            } catch (err) {
                console.error('error closing batch', err);
                if (err?.response.status === 400) {
                    toast.error(`Batch already exists with selected Payment Date`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(`Error opening batch ${batchName}.`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        }
    };

    const handleReceipt = () => {
        let errors = receiptError;
        if (!phoneNumber && textCheck) {
            if (!email && emailCheck) {
                errors = { ...errors, email: 'Required' }
            }
            errors = { ...errors, text: 'Required' }
            setReceiptError(errors)
            return;
        }
        if (!email && emailCheck) {
            if (!phoneNumber && textCheck) {
                errors = { ...errors, text: 'Required' }
            }
            errors = { ...errors, email: 'Required' }
            setReceiptError(errors)
            return;
        }
        setLoading(true);
        axios.get(`${apiRoute}/api/PrintReceiptViews/byReceipt/${receiptNumber}?print=${printCheck}&email=${emailCheck}&phoneNumber=${phoneNumber}&emailAddress=${email}&MasterReceipt=${masterReceipt}`, { responseType: 'arraybuffer' })
            .then((response) => {
                if (printCheck) {
                    const arrayBufferView = new Uint8Array(response.data);
                    const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                    const dataUrl = URL.createObjectURL(blob);
                    window.open(dataUrl, '_blank');
                }
                if (textCheck) {
                    axios.get(`${apiRoute}/api/PrintReceiptViews/byReceipt/text/${receiptNumber}?phoneNumber=${phoneNumber}&MasterReceipt=${masterReceipt}&caseNumber=${caseNumber}`)
                }
            })
            .then(() => {
                setReceiptNumber('');
                setMasterReceipt(0);
                handleClose();
            })
            .catch(error => {
                console.error('Error fetching receipt from front end:', error);
                toast.error('Error handling receipt.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const reprintReceiptWithTextAndEmail = () => {
        let errors = receiptError;
        if (!phoneNumber && textCheck) {
            if (!email && emailCheck) {
                errors = { ...errors, email: 'Required' }
            }
            errors = { ...errors, text: 'Required' }
            setReceiptError(errors)
            return;
        }
        if (!email && emailCheck) {
            if (!phoneNumber && textCheck) {
                errors = { ...errors, text: 'Required' }
            }
            errors = { ...errors, email: 'Required' }
            setReceiptError(errors)
            return;
        }

        const number = phoneNumber === '' ? 0 : phoneNumber;


        if (printCheck && !textCheck && !emailCheck) {
            if (retrieveReceiptPayment[0].receiptImage != null) {
                window.open(retrieveReceiptPayment[0].receiptImage);
                setRetrieveReceiptPayment([]);
                handleClose();
                return;
            } else {
                toast.error('Something Went Wrong ', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return;
            }
        }

        setLoading(true);
        axios.post(`${apiRoute}/api/PrintReceiptViews/retrieveReceipt?phoneNumber=${number}&email=${email}`, retrieveReceiptPayment[0])
            .then((response) => {
                setLoading(false);
                toast.success('Receipt has been sent successfully!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .then((res) => {
                handleClose();
            })
            .then((res) => {
                if (printCheck) {
                    window.open(retrieveReceiptPayment[0]?.receiptImage);
                }
            })
            .catch((err) => {
                console.log('err: ', err.response.data);
                toast.error(err.response.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => {
                setLoading(false);
                setRetrieveReceiptPayment([]);
            });
    };

    // CREDIT CARD PAYMENT TYPE DISABLED HERE!!!
    const options = paymentOptions.filter((p) => p.code !== "CREDIT CARD" && p.code !== "TIME SERVED");

    const caseBalanceStyle = {
        "& .MuiInputBase-root": {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black",
        },
        "& .MuiInputLabel-root": {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === "dark" ? "1px 1px 2px black" : "none",
        },
        width: "40%",
    };

    // creditCard modal violation table styles
    const tBackground = mode === 'dark' ? '#313131' : 'white';
    const violationBorder = !!noViolation ? '1px solid #dd4136' : '1px solid transparent';
    return (
        <>
            {
                (modalType === 'newPayment' || modalType === 'quickPayment') && (<Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ textAlign: 'center', backgroundColor: 'steelblue', color: 'white', p: '1vh', display: 'flex', justifyContent: 'center' }}>
                        <h1>
                            <strong>
                                {
                                    modalType === 'newPayment' ? 'Time Served' : 'Cash & Checks'
                                }
                            </strong>
                        </h1>
                    </Paper>
                    <Box elevation={10} sx={{ maxHeight: '70vh', minHeight: 'auto', height: 'auto', overflowX: 'hidden', overflowY: 'auto', width: '100%' }}>

                        <Box sx={{ width: '99%', m: 'auto', mt: '1vh', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '.5vh', overflowX: 'auto', }}>
                            {
                                modalType === 'newPayment' && (
                                    <Box>
                                        <strong>Violation: {violationView.description}</strong>
                                    </Box>
                                )
                            }
                            {
                                modalType === 'quickPayment' && (
                                    <>

                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'right', mr: '4vh' }}>
                                            <strong>Current Case Balance: </strong>
                                            <TextField
                                                sx={{ ...caseBalanceStyle, width: '10rem' }}
                                                size="small"
                                                value={commaMoneyFormat(balance)}
                                                id="balance"
                                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'right', mr: '4vh' }}>
                                            <strong>Remaining Case Balance After Payment: </strong>
                                            <TextField
                                                sx={{ ...caseBalanceStyle, width: '10rem' }}
                                                size="small"
                                                value={commaMoneyFormat(balance - (totalPayment - change))}
                                                id="balance"
                                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                                            />
                                        </Box>
                                    </>
                                )
                            }
                            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'right', mr: '4vh' }}>
                                {modalType === 'quickPayment'
                                    ?
                                    <strong>Remaining Balance On Selected Violations: </strong>
                                    :
                                    <strong>Remaining Balance On Selected Violation After Payment: </strong>
                                }
                                <TextField
                                    sx={{ ...caseBalanceStyle, width: '10rem' }}
                                    size="small"
                                    value={commaMoneyFormat(modalType === 'newPayment' && violationBalance > '0.00' ? displayBalance : modalType === 'quickPayment' && newCaseBalance > 0.00 ? newCaseBalance : '0.00')}
                                    id="balance"
                                    inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <PaymentForm
                                handleClose={handleClose}
                                openBatches={openBatches}
                                violation={violation}
                                updateCaseBalance={updateCaseBalance}
                                modalType={modalType}
                                ts={ts}
                                displayBalance={displayBalance}
                                setDisplayBalance={setDisplayBalance}
                                violationBalance={calculatedViolationBalance}
                                loading={loading}
                                setLoading={setLoading}
                                remitterState={state}
                                remitterDispatch={dispatch}
                                handleOpen={handleOpen}
                                receiptNumber={receiptNumber}
                                setReceiptNumber={setReceiptNumber}
                                setNewCaseBalance={setNewCaseBalance}
                                newCaseBalance={newCaseBalance}
                                citationState={citationState}
                                setMasterReceipt={setMasterReceipt}
                                grand={grand}
                                setGrand={setGrand}
                                change={change}
                                setChange={setChange}
                                totalPayment={totalPayment}
                                setTotalPayment={setTotalPayment}
                                overridedPartialPayment={overridedPartialPayment}
                            />
                        </Box>
                    </Box>
                    {
                        loading && <LinearProgress />
                    }
                </Paper>
                )
            }

            {
                modalType === 'creditCard' && (
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }} elevation={10}>
                            <h1>
                                <strong>
                                    Credit Card Payment
                                </strong>
                            </h1>
                        </Paper>
                        <Box elevation={10} sx={{ maxHeight: '70vh', minHeight: 'auto', height: 'auto', overflowX: 'hidden', overflowY: 'auto', width: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', mt: '2vh', mb: '1vh', width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '1.5vw', pl: '1vw', width: '45%' }}>
                                    <strong>Credit Card Payments Include A Convenience Fee of {convenienceFeeRate}%</strong>
                                    <Card sx={{ width: '95%', mt: '1vh', pl: 0 }}>
                                        <CardContent sx={{ p: '2vh' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="h6" sx={{ flex: 1 }}><strong>Current Case Balance:</strong></Typography>
                                                    <Typography variant="h6" sx={{ textAlign: 'right' }}>
                                                        {commaMoneyFormat(currentCaseBalance)}
                                                    </Typography>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="h6" sx={{ flex: 1 }}><strong>Remaining Case Balance:</strong></Typography>
                                                    <Typography variant="h6" sx={{ textAlign: 'right' }}>
                                                        {commaMoneyFormat(currentCaseBalance - ccPaymentAmount)}
                                                    </Typography>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="h6" sx={{ flex: 1 }}><strong>Amount Received:</strong></Typography>
                                                    <Typography variant="h6" sx={{ textAlign: 'right' }}>
                                                        {commaMoneyFormat(ccPaymentAmount)}
                                                    </Typography>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="h6" sx={{ flex: 1 }}><strong>Convenience Fee:</strong></Typography>
                                                    <Typography variant="h6" sx={{ textAlign: 'right' }}>
                                                        {commaMoneyFormat(convenienceFee)}
                                                    </Typography>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="h6" sx={{ flex: 1 }}><strong>Grand Total:</strong></Typography>
                                                    <Typography variant="h6" sx={{ textAlign: 'right' }}>
                                                        {commaMoneyFormat(grandTotal)}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Box>
                                <Box sx={{ width: '60%', m: '0 auto' }}>
                                    {
                                        quickCheck === false && (
                                            <Box sx={{ m: '0' }}>
                                                <Paper sx={{ ml: '.2vw', mr: '1vw' }} elevation={10}>
                                                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }} elevation={10}>
                                                        <h4>
                                                            <strong>
                                                                Select a violation for this payment
                                                            </strong>
                                                        </h4>
                                                    </Paper>
                                                    <div style={{ maxHeight: '33vh', minHeight: 'auto', height: 'auto', overflow: 'auto', pl: '1vh', pr: '1vh', pt: '0', pb: '1vh', display: 'flex', justifyContent: 'center' }}>
                                                        <table style={{ borderCollapse: 'separate', textAlign: 'center', width: '100%', tableLayout: 'fixed' }}>

                                                            <thead>
                                                                <tr style={{ position: 'sticky', top: 0, backgroundColor: tBackground, zIndex: 1, width: '100%' }}>
                                                                    <th style={{ width: '15%', wordWrap: 'break-word' }}></th>
                                                                    <th style={{ width: '20%', wordWrap: 'break-word' }}>Charge Type</th>
                                                                    <th style={{ width: '20%', wordWrap: 'break-word' }}>Statute</th>
                                                                    <th style={{ width: '25%', wordWrap: 'break-word' }}>Description</th>
                                                                    <th style={{ width: '20%', wordWrap: 'break-word' }}>Balance</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {chargesRows?.filter(cr => cr?.amendedDate == null).map((v, index) => {
                                                                    const { pkViolationId, chargeType, currentStatute, description, remainingBalance } = v;
                                                                    return (
                                                                        <tr key={pkViolationId}>
                                                                            <td>
                                                                                <FormControlLabel key={index}
                                                                                    value={state?.violationIdFk}
                                                                                    control={<Checkbox />}
                                                                                    checked={checkedItems?.findIndex(c => c?.pkViolationId === pkViolationId) !== -1}
                                                                                    onChange={() => {
                                                                                        const checkedItemIndex = checkedItems?.findIndex((item) => item?.pkViolationId === pkViolationId);
                                                                                        if (checkedItemIndex === -1) {
                                                                                            setCheckedItems([...checkedItems, v]);
                                                                                            setPkViolations([...pkViolations, v]);
                                                                                        } else if (checkedItemIndex > -1) {
                                                                                            const updatedCheckedItems = checkedItems.filter((item) => item.pkViolationId !== pkViolationId);
                                                                                            setCheckedItems(updatedCheckedItems);
                                                                                            setPkViolations(updatedCheckedItems);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td>{chargeType}</td>
                                                                            <td style={{ paddingLeft: '' }}>{currentStatute}</td>
                                                                            <td style={{ paddingLeft: '' }}>{description}</td>
                                                                            <td style={{ paddingLeft: '' }}>{commaMoneyFormat(remainingBalance)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Paper>
                                                {pkViolations.length === 0 && <Box sx={{ textAlign: 'center', mt: '.5vh' }}>
                                                    {
                                                        pkViolations.length === 0 && <span style={{ color: '#dd4136' }}>PLEASE SELECT A VIOLATION</span>
                                                    }
                                                </Box>}
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Box>

                            <Box sx={{ width: '99%', m: '1vh auto' }} pb={'1vh'}>
                                <CreditCardModal
                                    ts={ts}
                                    violation={violation}
                                    setViolation={setViolation}
                                    setLoading={setLoading}
                                    loading={loading}
                                    quickCheck={quickCheck}
                                    updateCaseBalance={updateCaseBalance}
                                    handleClose={handleClose}
                                    setNoViolation={setNoViolation}
                                    violationBalance={violationBalance}
                                    convenienceFee={convenienceFee}
                                    setConvenienceFee={setConvenienceFee}
                                    convenienceRate={convenienceRate}
                                    grandTotal={grandTotal}
                                    setGrandTotal={setGrandTotal}
                                    setCcPaymentAmount={setCcPaymentAmount}
                                    ccPaymentAmount={ccPaymentAmount}
                                    setReceiptNumber={setReceiptNumber}
                                    handleOpen={handleOpen}
                                    setMasterReceipt={setMasterReceipt}
                                    isCCPayment={true}
                                    statuteDesc={selectedStatuteDesc}
                                    selectedViolations={pkViolations}
                                />
                            </Box>
                        </Box>
                        {
                            loading && <LinearProgress />
                        }
                    </Paper>
                )
            }
            {
                modalType === 'editType' && (
                    <Paper elevation={10}>
                        <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center' }} elevation={10}>
                            <h1>
                                <strong>Edit Payment Type</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ p: '1rem', display: 'flex' }}>
                            <Box p={'1rem'}>
                                <TextField
                                    select
                                    id="paymentType"
                                    label="Type"
                                    variant="outlined"
                                    defaultValue={options[0].description}
                                    InputLabelProps={{ shrink: true }}
                                    value={paymentType}
                                    onChange={(e) => { setPaymentType(e.target.value) }}
                                >
                                    {
                                        options?.map(({ code, description }) => {
                                            return <MenuItem key={code} value={code}>{description}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Box>
                            {
                                (paymentType === "MONEY ORDER" || paymentType === "CCHECK") &&
                                <Box sx={{ p: '1rem', maxWidth: '12rem' }}>
                                    <TextField
                                        id="checkNumber"
                                        label="Check Number"
                                        variant="outlined"
                                        width="auto"
                                        InputLabelProps={{ shrink: true }}
                                        value={selectedPayment.checkNumber}
                                        onChange={(e) => { setCheckNumber(e.target.value.replace(/[^0-9.]/g, '')) }}
                                    />
                                </Box>
                            }
                            <Box sx={{ display: 'flex', p: '1rem' }}>
                                <Button variant="contained" onClick={editPaymentType} sx={{ float: 'right', mr: '2rem', py: '1rem', backgroundColor: 'steelblue', color: 'white' }}>
                                    Save Changes
                                </Button>
                                <Button variant="contained" onClick={handleClose} sx={{ float: 'right', backgroundColor: 'steelblue', color: 'white' }}>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                        {
                            loading && <LinearProgress />
                        }
                    </Paper>
                )
            }
            {
                (modalType === 'refundPayment' || modalType === 'reversePayment' || modalType === 'voidPayment') && (
                    <>
                        <Paper elevation={10}>
                            <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center' }} elevation={10}>
                                <h1>
                                    <strong>
                                        {modalType === 'refundPayment' ? 'Refund Payment' : modalType === 'voidPayment' ? 'Void Payment' : 'Reverse Payment'}
                                    </strong>
                                </h1>
                            </Paper>
                            <Box sx={{ p: '1vh' }}>
                                <h3>Do you want to {modalType === 'refundPayment' ? 'REFUND' : modalType === 'voidPayment' ? 'VOID' : 'REVERSE'} the {selectedPayment.paymentType} payment made on {selectedPayment.dateEnter} of {commaMoneyFormat(selectedPayment.paymentAmount)} for violation {selectedPayment.paymentRef}?</h3>
                            </Box>
                            {
                                (modalType === 'refundPayment' || modalType === 'reversePayment') && (
                                    <Box sx={{ p: '1vh' }}>
                                        <RemitterForm
                                            ts={ts}
                                            dispatch={dispatch}
                                            state={state}
                                            selectedPayment={selectedPayment}
                                            calledFrom="Case History"
                                            modalType={modalType}
                                        />
                                    </Box>
                                )
                            }
                            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', overflow: 'hidden' }}>
                                <h5 style={{ marginTop: '1vh', marginLeft: '1rem' }}>Batches: </h5>&nbsp;&nbsp;
                                <Box sx={{ width: '100%' }}>
                                    <RadioGroup
                                        row
                                        name='voidBatchNumber'
                                        value={updatePaymentBatchNumber}
                                        onChange={(e) => setUpdatePaymentBatchNumber(e.target.value)}
                                    >
                                        {
                                            // destructured batchNumber out of batch object to make this easier to read
                                            openBatches.sort((a, b) => a.batchNumber < b.batchNumber ? -1 : 1).map(({ batchName, batchNumber }) => (
                                                <FormControlLabel
                                                    key={batchNumber}
                                                    value={batchNumber}
                                                    control={<Radio />}
                                                    label={batchName ? `${batchName}(${batchNumber})` : batchNumber}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </Box>
                            </Box>
                            <Box sx={{ p: '1vh' }}>
                                <TextField
                                    sx={ts}
                                    multiline
                                    rows={6}
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    fullWidth
                                    label="Comments"
                                    variant="outlined"
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right', p: '1vh' }}>
                                <Button
                                    onClick={
                                        modalType === 'refundPayment' ? refundPayment
                                            : modalType === 'voidPayment' ? voidPayment
                                                : reversePayment
                                    }
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                    disabled={notes.length < 1}
                                >
                                    {modalType === 'refundPayment' ? 'Refund'
                                        : modalType === 'voidPayment' ? 'Void'
                                            : 'Reverse'} Payment
                                </Button>
                                <Button
                                    onClick={() => handleClose() && setUpdatePaymentBatchNumber('0')}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Paper>
                        {
                            loading && <LinearProgress />
                        }
                    </>
                )
            }
            {
                modalType === 'openBatch' && (
                    <OpenBatchModal
                        newBatch={newBatch}
                        ts={ts}
                        batchDate={batchDate}
                        setBatchDate={setBatchDate}
                        handleBatchOpen={handleBatchOpen}
                        batchType={batchType}
                        setBatchType={setBatchType}
                        handleClose={handleClose}
                        modalType={modalType}
                        selectedRow={selectedRow}
                        batchName={batchName}
                        setBatchName={setBatchName}
                    />
                )
            }
            {
                modalType === 'printReceipt' && (
                    <Paper elevation={10}>
                        <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center' }} elevation={10}>
                            <h1>
                                <strong>Print/Email Receipt</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ p: '1rem' }}>
                            <h5 style={{ textAlign: 'center' }}>
                                Do you want to print or email or text a receipt for this payment?
                            </h5>
                            <hr />
                            <Box sx={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}>
                                <FormControlLabel
                                    control={<Checkbox checked={printCheck} onChange={(e) => setPrintCheck(e.target.checked)} />}
                                    label="Print"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={emailCheck} onChange={(e) => setEmailCheck(e.target.checked)} />}
                                    label="Email"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={textCheck} onChange={(e) => setTextCheck(e.target.checked)} />}
                                    label="Text"
                                />
                            </Box>
                            {
                                emailCheck &&
                                <Box sx={{ mt: '1vh', mb: '1vh' }}>
                                    <h5 style={{ textAlign: 'center' }}>
                                        Enter the email to send the receipt to below.
                                    </h5>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ width: '75%' }}
                                            id="email"
                                            variant="outlined"
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                            onBlur={(e) => {
                                                !e.target.value
                                                    ? setReceiptError({ ...receiptError, email: 'Required' })
                                                    : setReceiptError({ ...receiptError, email: null })
                                            }}
                                            helperText={receiptError?.email}
                                            error={!!receiptError?.email}
                                        />
                                    </Box>
                                </Box>
                            }
                            {textCheck &&
                                <Box sx={{ mt: '1vh', mb: '1vh' }}>
                                    <h5 style={{ textAlign: 'center' }}>
                                        Enter the phone number to send the receipt to below.
                                    </h5>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ width: '75%' }}
                                            id="phoneNumber"
                                            variant="outlined"
                                            value={phoneNumber}
                                            onChange={(e) => { setPhoneNumber(e.target.value) }}
                                            onBlur={(e) => {
                                                !e.target.value
                                                    ? setReceiptError({ ...receiptError, text: 'Required' })
                                                    : setReceiptError({ ...receiptError, text: null })
                                            }}
                                            helperText={receiptError?.text}
                                            error={!!receiptError?.text}
                                        />
                                    </Box>
                                </Box>
                            }
                            <hr />
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right', }}>
                                <Button variant="contained" onClick={handleReceipt} sx={{ backgroundColor: 'steelblue', color: 'white' }}>Print/Send Receipt</Button>
                                <Button variant="contained" onClick={() => { handleReceipt(); handleClose(); setReceiptNumber(''); setMasterReceipt(0) }} sx={{ backgroundColor: 'steelblue', color: 'white' }}>Skip</Button>
                            </Box>
                        </Box>
                        {
                            loading && <LinearProgress />
                        }
                    </Paper>
                )
            }
            {
                modalType === 'retrieveReceipt' && (
                    <Paper elevation={10}>
                        <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center' }} elevation={10}>
                            <h1>
                                <strong>Print/Email Receipt</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ p: '1rem' }}>
                            <h5 style={{ textAlign: 'center' }}>
                                Would you like to reprint, email or text the original receipt for this payment?
                            </h5>
                            <hr />
                            <Box sx={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}>
                                {
                                    !!retrieveReceiptPayment[0]?.receiptImage && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={printCheck}
                                                    onChange={(e) => setPrintCheck(e.target.checked)}
                                                />
                                            }
                                            label="Print"
                                        />
                                    )
                                }
                                <FormControlLabel
                                    control={<Checkbox checked={emailCheck} onChange={(e) => setEmailCheck(e.target.checked)} />}
                                    label="Email"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={textCheck} onChange={(e) => setTextCheck(e.target.checked)} />}
                                    label="Text"
                                />
                            </Box>
                            {
                                emailCheck &&
                                <Box sx={{ mt: '1vh', mb: '1vh' }}>
                                    <h5 style={{ textAlign: 'center' }}>
                                        Enter the email to send the receipt to below.
                                    </h5>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ width: '75%' }}
                                            id="email"
                                            variant="outlined"
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                            onBlur={(e) => {
                                                !e.target.value
                                                    ? setReceiptError({ ...receiptError, email: 'Required' })
                                                    : setReceiptError({ ...receiptError, email: null })
                                            }}
                                            helperText={receiptError?.email}
                                            error={!!receiptError?.email}
                                        />
                                    </Box>
                                </Box>
                            }
                            {textCheck &&
                                <Box sx={{ mt: '1vh', mb: '1vh' }}>
                                    <h5 style={{ textAlign: 'center' }}>
                                        Enter the phone number to send the receipt to below.
                                    </h5>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ width: '75%' }}
                                            id="phoneNumber"
                                            variant="outlined"
                                            value={phoneNumber}
                                            onChange={(e) => { setPhoneNumber(e.target.value) }}
                                            onBlur={(e) => {
                                                !e.target.value
                                                    ? setReceiptError({ ...receiptError, text: 'Required' })
                                                    : setReceiptError({ ...receiptError, text: null })
                                            }}
                                            helperText={receiptError?.text}
                                            error={!!receiptError?.text}
                                        />
                                    </Box>
                                </Box>
                            }
                            <hr />
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right', }}>
                                <Button variant="contained" onClick={reprintReceiptWithTextAndEmail} sx={{ backgroundColor: 'steelblue', color: 'white' }}>Print/Send Receipt</Button>
                                <Button variant="contained" onClick={() => { handleClose(); setReceiptNumber(''); setMasterReceipt(0); setRetrieveReceiptPayment([]) }} sx={{ backgroundColor: 'steelblue', color: 'white' }}>Cancel</Button>
                            </Box>
                        </Box>
                        {
                            loading && <LinearProgress />
                        }
                    </Paper>
                )
            }
        </>
    )
}

export default PaymentModals;