import React, { createContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { apiRoute } from '../App.js';

const UserContext = createContext({
    entityId: '',
    setEntityId: () => { },
    entityName: '',
    setEntityName: () => { },
    userName: '',
    SetUserName: () => { },
    shiftDate: '',
    setShiftDate: () => { },
    submitSelectedDate: () => { },
    userId: '',
    setUserId: () => { },
    loading: Boolean,
    setLoading: () => { },
    userRoles: [],
    setUserRoles: () => { },
    allTabs: Boolean,
    setAllTabs: () => { },
    filesTab: Boolean,
    setFilesTab: () => { },
    schoolZoneFine: 0,
    setSchoolZoneFine: () => { },
    constructionZoneFine: 0,
    setConstructionZoneFine: () => { },
    schoolZoneFormula: 0,
    setSchoolZoneFormula: () => { },
    constructionZoneFormula: 0,
    setConstructionZoneFormula: () => { },
    paymentEmail: String,
    setPaymentEmail: () => { },
    convenienceFeeRate: 0.00,
    setConvenienceFeeRate: () => { },
    adminCheck: Boolean,
    setAdminCheck: () => { },
    supervisorCheck: Boolean,
    setSupervisorCheck: () => { },
    superAdminCheck: Boolean,
    setSuperAdminCheck: () => { },
    userRoleChoices: [],
    setUserRoleChoices: () => { },
    users: [],
    setUsers: () => { },
    useFeeSchedule: Number,
    setUseFeeSchedule: () => { },
    changes: Boolean,
    setChanges: () => { },
    modalType: String,
    setModalType: () => { },
    changesModal: Boolean,
    setChangesModal: () => { },
    handleChangesModal: () => { },
    originalState: Object,
    setOriginalState: () => { },
    from: [],
    setFrom: () => { },
    entityState: String,
    setEntityState: () => { },
    entityZip: String,
    setEntityZip: () => { },
    officers: Array,
    setOfficers: () => { },
    banks: Array,
    setBanks: () => { },
    getBanks: () => { },
    allPayees: [],
    setAllPayees: () => { }
})

const UserProvider = ({ children }) => {
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [entityId, setEntityId] = useState('');
    const [entityName, setEntityName] = useState('');
    const [allTabs, setAllTabs] = useState(true);
    const [filesTab, setFilesTab] = useState(true);
    const [schoolZoneFine, setSchoolZoneFine] = useState(0);
    const [constructionZoneFine, setConstructionZoneFine] = useState(0);
    const [schoolZoneFormula, setSchoolZoneFormula] = useState(0);
    const [constructionZoneFormula, setConstructionZoneFormula] = useState(0);
    const [paymentEmail, setPaymentEmail] = useState('');
    const [convenienceFeeRate, setConvenienceFeeRate] = useState(0.00);
    const [adminCheck, setAdminCheck] = useState(false);
    const [supervisorCheck, setSupervisorCheck] = useState(false);
    const [superAdminCheck, setSuperAdminCheck] = useState(false);
    const [userRoleChoices, setUserRoleChoices] = useState([]);
    const [users, setUsers] = useState([]);
    const [useFeeSchedule, setUseFeeSchedule] = useState(Number)
    const [changes, setChanges] = useState(false);
    const [modalType, setModalType] = useState();
    const [changesModal, setChangesModal] = useState(false);

    const [originalState, setOriginalState] = useState({});
    const currentDate = new Date();
    const formattedDefaultDate = currentDate.toISOString().split('T')[0]; // Extract date part
    const [shiftDate, setShiftDate] = useState(formattedDefaultDate);
    const [from, setFrom] = useState([]);
    const [entityState, setEntityState] = useState('');
    const [entityZip, setEntityZip] = useState('');

    const [loading, setLoading] = useState(true);
    const [officers, setOfficers] = useState([]);
    const [banks, setBanks] = useState([]);
    const [allPayees, setAllPayees] = useState([]);

    const navigate = useNavigate();

    const fetchUserData = useCallback(async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const passwordToken = localStorage.getItem('passwordToken');
        if (token) {
            const decodedToken = jwt_decode(token);
            const userDataClaim = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
            const userGivenName = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
            const userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
            const entityId = userDataClaim ? userDataClaim.toString() : null;
            const userName = userGivenName;

            setEntityId(entityId);
            setUserName(userName);
            setUserId(userId);

            if (entityId && userId) {
                axios.get(`${apiRoute}/api/entities/${entityId}`)
                    .then(({ data }) => {
                        const { entityName, filesTab, schoolAmount, constructionAmount, schoolFormula, constructionFormula, paymentEmail, convenienceFeeRate, collectCondensed, useFeeSchedule, state, zip } = data
                        setEntityName(entityName);
                        filesTab === 'Y' ? setFilesTab(true) : setFilesTab(false);
                        setSchoolZoneFine(schoolAmount);
                        setConstructionZoneFine(constructionAmount);
                        setSchoolZoneFormula(schoolFormula);
                        setConstructionZoneFormula(constructionFormula);
                        setPaymentEmail(paymentEmail);
                        setConvenienceFeeRate((convenienceFeeRate * 100).toFixed(2))
                        const notCondensed = collectCondensed === 'Y' ? false : true;
                        setAllTabs(notCondensed);
                        setUseFeeSchedule(useFeeSchedule);
                        setEntityState(state);
                        setEntityZip(zip);
                    })
                    .then(() => {
                        axios.get(`${apiRoute}/api/users/`)
                            .then(({ data }) => {
                                setUsers(
                                    data.map((user) => ({
                                        ...user,
                                        id: user.pkUserId,
                                    }))
                                );
                            })
                            .catch((err) => {
                                console.log(err, 'err getting Users - getUsers');
                            })
                        axios.get(`${apiRoute}/api/userRoles/${userId}`)
                            .then(({ data }) => {
                                setUserRoles(data);
                                setAdminCheck(data?.some(({ roleName }) => roleName === "COLLECT_ADMIN" || roleName === "SUPER_ADMIN"));
                                setSupervisorCheck(data?.some(({ roleName }) => roleName === "COLLECT_SUPERVISOR" || roleName === "COLLECT_ADMIN" ||roleName === "SUPER_ADMIN"));
                                setSuperAdminCheck(data?.some(({ roleName }) => roleName === "SUPER_ADMIN"));
                            })
                            .catch((err) => console.log('user roles err: ', err))
                        axios.get(`${apiRoute}/api/syscodetbls/bytype/29`)
                            .then(({ data }) => {
                                setUserRoleChoices(data);
                            })
                            .catch((err) => console.log('syscode user role err: ', err))
                        axios.get(`${apiRoute}/api/officerTbls`).then(({data}) => setOfficers(data)).catch(err => console.log('err getAllOfficers', err))
                    })
                    .catch((err) => console.log('ENTITY ERR IN USERCONTEXT: ', err))
                    .finally(() => setLoading(false))

                // Get all Payees
                axios.get(`${apiRoute}/api/PayeeTbls`)
                    .then(({ data }) => {
                        setAllPayees(data);
                    })
                    .catch((err) => {
                        console.log(err, 'err getting Users - getUsers');
                    })
            }
        }
        else {
            setLoading(false);
            /*navigate(`/login`);*/
        }
    }, []);

    const handleChangesModal = () => {
        setChangesModal(!changesModal);
    }

    // Use this function in your useEffect
    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const submitSelectedDate = async () => {
        try {
            await axios.post(`${apiRoute}/api/users/shiftDate/${shiftDate}`)
        } catch (err) {
            console.log(err)
        }
    }

    const getBanks = () => {
        axios.get(`${apiRoute}/api/GlBankTbls`)
            .then(({ data }) => {
                setBanks(data);
            })
            .catch((err) => {
                console.log(err);
            })

    }

    const contextValue = {
        entityId: entityId,
        setEntityId,
        entityName: entityName,
        setEntityName,
        userName: userName,
        setUserName,
        shiftDate: shiftDate,
        setShiftDate,
        submitSelectedDate,
        userId: userId,
        setUserId,
        loading: loading,
        setLoading,
        fetchUserData,
        userRoles: userRoles,
        setUserRoles,
        allTabs: allTabs,
        setAllTabs,
        filesTab: filesTab,
        setFilesTab,
        schoolZoneFine: schoolZoneFine,
        setSchoolZoneFine,
        constructionZoneFine: constructionZoneFine,
        setConstructionZoneFine,
        schoolZoneFormula: schoolZoneFormula,
        setSchoolZoneFormula,
        constructionZoneFormula: constructionZoneFormula,
        setConstructionZoneFormula,
        paymentEmail: paymentEmail,
        setPaymentEmail,
        convenienceFeeRate: convenienceFeeRate,
        setConvenienceFeeRate,
        adminCheck: adminCheck,
        supervisorCheck: supervisorCheck,
        superAdminCheck: superAdminCheck,
        userRoleChoices: userRoleChoices,
        users: users,
        useFeeSchedule: useFeeSchedule,
        changes: changes,
        setChanges,
        modalType: modalType,
        setModalType,
        changesModal: changesModal,
        setChangesModal,
        handleChangesModal,
        originalState: originalState,
        setOriginalState,
        from: from,
        setFrom,
        entityState: entityState,
        setEntityState,
        entityZip: entityZip,
        setEntityZip,
        officers: officers,
        setOfficers,
        banks: banks,
        setBanks,
        getBanks,
        allPayees: allPayees,
        setAllPayees
    }

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;
export { UserContext, UserProvider };