import React, { useState, useContext } from 'react';
import { Box, Button, Paper, TextField } from '@mui/material';
import axios from 'axios';

import { apiRoute } from '../../../../App';

const DisburseBondTransferModal = (props) => {
    const {
        handleDisburseModalClose,
        selectedTransferId,
        disburseStep,
        setDisburseStep,
        existingTransfers,
        setExistingTransfers
    } = props;

    const [checkNumber, setCheckNumber] = useState("");
    const [checkId, setCheckId] = useState(0);
    const [checkDate, setCheckDate] = useState("");

    // component methods
    const handleDisburseBondTransfer = async () => {

        const disburseTransferData = {
            Id: selectedTransferId,
            CheckNumber: checkNumber
        };

        try {
            const response = await axios.post(`${apiRoute}/api/bond/disburse/transfer`, disburseTransferData);
            console.log("On Demand Disbursement Response object", response);
            const newTransferRows = existingTransfers.map((row) => {
                return row.id === response.data.bondTransfer.id ? response.data.bondTransfer : row
            });

            setCheckId(response.data?.checkId);
            setCheckDate(response.data?.checkDate);
            setExistingTransfers(newTransferRows);
            setDisburseStep("CHECK");
        } catch (error) {
            console.log('disburse on deman error', error);
            window.alert(error);
        }
    }

    const handlePrintDisbursementCheck = () => {
        axios.get(`${apiRoute}/api/PrintDisbursementChecks/print?checkId=${checkId}&checkDate=${checkDate}&checkNum=${checkNumber}`, { responseType: 'arraybuffer' })
            .then((response) => {
                const arrayBufferView = new Uint8Array(response.data);
                const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                const dataUrl = URL.createObjectURL(blob);
                window.open(dataUrl, '_blank');
            })
            .catch(error => {
                console.error('Error fetching check from front end:', error);
            })
            .finally(() => handleDisburseModalClose())
    }

    //useEffect

    return (
        <Paper elevation={10}>
            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                <h1>
                    <strong>Create On Demand Disbursement</strong>
                </h1>
            </Paper>
            {
                disburseStep === "DISBURSE" && (
                    <>
                        <Box sx={{ p: '1vh' }}>
                            <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                                <h2>Would you like to create a disbursement for this transaction?</h2>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mb: '2vh' }}>
                                <TextField
                                    label={"Check Number"}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ style: { textAlign: 'right' } }}
                                    sx={{ justifyContent: 'center' }}
                                    value={checkNumber}
                                    onChange={(e) => { const value = e.target.value.replace(/[^0-9.]/g, ''); setCheckNumber(value); console.log('set check Number here', checkNumber) }}
                                />
                            </Box>
                            <hr />
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                                <Button
                                    onClick={handleDisburseBondTransfer}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                    disabled={checkNumber.replace(/[^0-9.]/g, '') === ""}
                                >
                                    Yes
                                </Button>
                                <Button
                                    onClick={handleDisburseModalClose}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                >
                                    No
                                </Button>
                            </Box>
                        </Box>
                    </>
                )
            }
            {
                disburseStep === "CHECK" && (
                    <Box sx={{ p: '1vh' }}>
                        <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                            <h2>Would you like to print the Check Now?</h2>
                        </Box>
                        <hr />
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                            <Button
                                onClick={handlePrintDisbursementCheck}
                                variant="contained"
                                sx={{ backgroundColor: 'steelblue', color: 'white' }}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={handleDisburseModalClose}
                                variant="contained"
                                sx={{ backgroundColor: 'steelblue', color: 'white' }}
                            >
                                No
                            </Button>
                        </Box>
                    </Box>
                )
            }
        </Paper>
    )
};

export default DisburseBondTransferModal;