import React, { useContext, useEffect, useState } from 'react';

import { Button, LinearProgress } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { RiDeleteBinLine } from 'react-icons/ri';

import { getColumns } from '../components/UtilColumns';
import UserContext from '../../../../../context/UserContext';

const UtilitiesTable = (props) => {
    const { name, controller, tableHeight, setRowData, handleOpen, rows, getRows, loading } = props;

    const { useFeeSchedule, entityId } = useContext(UserContext)

    const [row, setRow] = useState();

    useEffect(() => {
        if (!!controller) {
            getRows();
        }
    }, [controller]);

    const utilColumns = getColumns(name);

    const cols = utilColumns;

    const column = [  
        ...cols,
        {
            field: 'edit',
            headerName: 'Edit',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                return (
                    <>
                        <Button
                            onClick={() => {
                                setRowData(row);
                                handleOpen(name, 'edit');
                            }}
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                            }}
                            style={{ padding: '7px', width: '2em', borderRadius: '8px', display: 'flex', justifyContent: 'center' }}
                        >
                            Edit
                        </Button>
                    </>
                );
            },
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 75,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                return (
                    <>
                        <Button
                            onClick={() => {
                                setRowData(row);
                                handleOpen(name, 'delete')
                            }}
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                width: '95%',
                                m: '0 auto',
                            }}
                            style={{ padding: '7px', fontSize: '23px', width: '2em', borderRadius: '8px', display: 'flex', justifyContent: 'center' }}
                        >
                            <RiDeleteBinLine />
                        </Button>
                    </>
                );
            },
        },
    ]

    const columnArray = ['Entity Info', 'Banks', 'Gl Accounts'];
    const columns = !columnArray.find((c) => { 
        return name === c       
    }) ? column : column.filter(c => c.field !== 'delete')

    return (
        <DataGrid
            rows={rows}
            columns={columns.map((column, index) => ({
                ...column,
                headerClassName: index === 0 ? 'first-column-header' : '',
                cellClassName: index === 0 ? 'first-column-cell' : '',
            }))}
            loading={loading}
            disableMultipleRowSelection
            onRowClick={(params) => {setRow(params.row) } }
            onCellDoubleClick={
                () => {
                    setRowData(row);
                    handleOpen(name, 'edit');
                }
            }
            density="compact"
            initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
            }}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: LinearProgress
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            sx={{
                '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                    marginTop: '1em',
                    marginBottom: '1em',
                },
                '.first-column-header': {
                    paddingLeft: '2em', // Adjust the padding value as needed
                },
                '.first-column-cell': {
                    paddingLeft: '1em', // Adjust the padding value as needed
                },
                maxHeight: tableHeight,
                minHeight: loading ? tableHeight : '15vh',
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                    outline: "none !important",
                },
            }}
            rowHeight={60}
            autoHeight={!rows.length}
            localeText={{ noRowsLabel: `No ${name} Found` }}
        />
    )
}

export default UtilitiesTable;