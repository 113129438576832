import React, { useReducer } from 'react';
import axios from 'axios';

import { Paper, Button, Box, TextField, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../../App';

const OfficerForm = (props) => {
    const {
        handleClose,
        data,
        loading,
        setLoading,
        modalType,
        ts,
        rows,
        setRows,
        getRows
    } = props;

    const initialState = {
        officerName: modalType === 'add' ? '' : data.officerName,
        officerRank: modalType === 'add' ? '' : data.officerRank,
        badgeNumber: modalType === 'add' ? '' : data.badgeNumber,
        agency: modalType === 'add' ? '' : data.agency,
    };

    const OfficersByBadgeNumber = rows.reduce((object, officer) => {
        object[officer.badgeNumber] = officer;
        return object;
    }, {})           

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'officerName':
                if (value.trim() === '') {
                    error = 'Officer Name Required';
                } else if (value.length < 1 || value.length > 255) {
                    error = 'Name must be between 1 and 255 characters';
                }
                break;
            case 'badgeNumber':
                if (value.trim() === '') {
                    error = 'Badge Number Required';
                }
                if (OfficersByBadgeNumber[value.trim()] !== undefined) {
                    error = 'Badge Number Already in use';
                }

                break;
            case 'agency':
                if (value.trim() === '') {
                    error = 'Agency is Required';
                }
                break;
            default:
                break;
        }

        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === 'add') {
            const officer = {
                officerName: state?.officerName,
                officerRank: state?.officerRank,
                badgeNumber: state?.badgeNumber,
                agency: state?.agency,
            }
            if (OfficersByBadgeNumber[officer.badgeNumber] !== undefined) {
                toast.error(`The badge number ${officer.badgeNumber} is already in use`, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                setLoading(false);
                return;
            }
            axios.post(`${apiRoute}/api/officerTbls`, officer)
                .then((res) => {
                    setRows([...rows, { ...res.data, id: res.data.pkOfficerId }])
                    toast.success('Officer added successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    if (!!err?.response?.data) {
                        toast.error(`${err?.response?.data?.message}`, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    } else {
                        toast.error(`Error ${err?.response?.status} while adding new User!`, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    }
                    console.log(err, 'Error adding user!');
                })
                .finally(() => { setLoading(false); getRows(); })
        } else {
            const officer = {
                pkOfficerId: data.pkOfficerId,
                officerName: state?.officerName,
                officerRank: state?.officerRank,
                badgeNumber: state?.badgeNumber,
                agency: state?.agency,
            }
            if (OfficersByBadgeNumber[officer.badgeNumber] !== undefined) {
                toast.error(`The badge number ${officer.badgeNumber} is already in use`, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                setLoading(false);
                return;
            }
            axios.put(`${apiRoute}/api/officerTbls`, officer)
                .then((res) => {
                    const updatedRows = rows?.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...officer };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('User updated successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    if (!!err?.response?.data) {
                        toast.error(`${err?.response?.data}`, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    } else {
                        toast.error(`Error ${err?.response?.status} while updating new User!`, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    }
                    console.log(err, 'Error updating user');
                })
                .finally(() => { setLoading(false); getRows() })
        }
    }
    console.log('Officer with the inputted badgeNumber', OfficersByBadgeNumber[state.badgeNumber]);
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                        <h1>
                            <strong>
                                {
                                    modalType === 'add' ? 'Add User' : 'Edit User'
                                }
                            </strong>
                        </h1>
                    </Paper>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="name"
                                    label="Officer Name"
                                    name="name"
                                    value={state.officerName}
                                    onChange={(e) => {
                                        handleFieldChange('officerName', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('officerName', e.target.value) }}
                                    helperText={state?.errors?.officerName}
                                    error={!!state?.errors?.officerName}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="officerRank"
                                    label="Officer Rank"
                                    name="officerRank"
                                    type="text"
                                    value={state.officerRank}
                                    onChange={(e) => {
                                        handleFieldChange('officerRank', e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        handleBlur('officerRank', e.target.value.replace(/[^a-zA-Z\s]/g, ''))
                                    }}
                                    helperText={state?.errors?.officerRank}
                                    error={!!state?.errors?.officerRank}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="badgeNumber"
                                    label="Badge Number"
                                    name="badgeNumber"
                                    type="text"
                                    value={state.badgeNumber}
                                    onChange={(e) => {
                                        handleFieldChange('badgeNumber', e.target.value.replace(/[^0-9]/g, ''));
                                    }}
                                    onBlur={(e) => { handleBlur('badgeNumber', e.target.value) }}
                                    helperText={state?.errors?.badgeNumber}
                                    error={!!state?.errors?.badgeNumber}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="agency"
                                    label="Law Enforcement Agency"
                                    name="agency"
                                    type="text"
                                    value={state.agency}
                                    onChange={(e) => {
                                        handleFieldChange('agency', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('agency', e.target.value) }}
                                    helperText={state?.errors?.agency}
                                    error={!!state?.errors?.agency}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Paper>
            </form>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default OfficerForm;
