import React, { useState, createContext, useMemo, useEffect, useContext } from 'react';
import jwt_decode from "jwt-decode";
import axios from "axios";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import AppRoutes from './AppRoutes';
import DesotoRoutes from './DesotoRoutes';
import UserContext from './context/UserContext';
import './css/custom.css';

import {ToastContainer } from 'react-toastify';
 
export const ThemeModeContext = createContext({
    mode: 'light',
    toggleMode: () => { }
});

export const apiRoute = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE
export const frontendRoute = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_FRONTEND_MODE : process.env.REACT_APP_PRO_MODE

const App = () => {
    // State to hold the current theme mode
    const [mode, setMode] = useState('light');
    const { entityId } = useContext(UserContext);

    useEffect(() => {
        const savedMode = localStorage.getItem('mode');
        if (savedMode) {
            setMode(savedMode);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('mode', mode);
    }, [mode]);

    // Memoized object with the toggle function to update the theme mode
    const toggleMode = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    // Create the theme object with the current mode
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                },
            }),
        [mode]
    );

    const hasToken = localStorage.getItem('token') !== null;
    const token = localStorage.getItem('token');

    // Token is valid, set it as a default Authorization header for axios
    axios.defaults.headers.common = {
        'Authorization': `Bearer ${token}`
    };
    if (hasToken) {
        // Decode the token to get the expiration date
        const decodedToken = jwt_decode(token); // Assuming you have a jwt_decode function available
        const expirationDate = new Date(decodedToken.exp * 1000); // Convert the expiration timestamp to milliseconds
        // Check if the token is still valid
        const isTokenValid = expirationDate > new Date();
        if (!isTokenValid) {
            // Token has expired, render the login route
            localStorage.removeItem("token");
            localStorage.removeItem('shiftDate');
            localStorage.removeItem('selectedTab');
        }

    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ThemeModeContext.Provider value={{ mode, toggleMode }}>
                    <AppRoutes />
            </ThemeModeContext.Provider>
            <ToastContainer />
        </ThemeProvider>
    );
}

export default App;