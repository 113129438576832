import React, { useContext, useState } from 'react';
import axios from 'axios';

import { Box } from '@mui/material';

import ChargesBox from '../../components/sections/paymentsTab/ChargesBox';
import CaseHistoryBox from '../../components/sections/paymentsTab/CaseHistoryBox';

import { apiRoute } from '../../App.js';
import { BatchContext } from '../../context/BatchMgmtContext';
import UserContext from '../../../src/context/UserContext.js';

const PaymentsView = (props) => {
    const { updateCaseBalance, ts, state } = props;

    const { openBatches, setPaymentBatchNumber } = useContext(BatchContext);

    const [violationBalance, setViolationBalance] = useState(0);

    const [newBatch, setNewBatch] = useState({});

    const [modalType, setModalType] = useState('');
    const [modalWidth, setModalWidth] = useState('');
    const [modal, setModal] = useState(false);

    const [violation, setViolation] = useState({});
    const [selectedPayment, setSelectedPayment] = useState({});
    const {userName} = useContext(UserContext);

    // STATE THAT HOLDS THE RECEIPT NUMBER OF THE NEWLY MADE PAYMENT
    const [receiptNumber, setReceiptNumber] = useState('');

    const [loading, setLoading] = useState(false);
    const [retrieveReceiptPayment, setRetrieveReceiptPayment] = useState([]);

    const handleOpen = async (type, width) => {
        if (openBatches.length > 0 || type === 'retrieveReceipt') {
            setModalType(type);
            setModalWidth(width);
            setModal(true);
        } else {
            try {
                setModalType('openBatch');
                setModalWidth('25%');
                setModal(true);
            } catch (err) {
                console.log('error getting newBatch', err);
            }
        }
    };

    const handleClose = () => {
        setModal(false);
        setModalType('');
        setPaymentBatchNumber(null);
        if (modalType === 'creditCard') {
            setViolation({})
        }
    };


    const retrieveReceipt = async (params) => {
        await axios.get(`${apiRoute}/api/ffpaymenttbls/${params.row.id}`)
            .then(res =>
            {
                setRetrieveReceiptPayment(res.data)
            })
            .catch(err => console.log('err fetching receipt from the front end: ', err));
        handleOpen('retrieveReceipt', '30%');
    };

    return (
        <Box sx={{ width: '99%', m: '0 auto' }}>
            <ChargesBox
                modal={modal}
                handleClose={handleClose}
                handleOpen={handleOpen}
                violationBalance={violationBalance}
                setViolationBalance={setViolationBalance}
                openBatches={openBatches}
                updateCaseBalance={updateCaseBalance}
                violation={violation}
                setViolation={setViolation}
                modalType={modalType}
                modalWidth={modalWidth}
                ts={ts}
                loading={loading}
                setLoading={setLoading}
                selectedPayment={selectedPayment}
                newBatch={newBatch}
                receiptNumber={receiptNumber}
                setReceiptNumber={setReceiptNumber}
                state={state}
                userName={userName}
                retrieveReceipt={retrieveReceipt}
                setRetrieveReceiptPayment={setRetrieveReceiptPayment}
                retrieveReceiptPayment={retrieveReceiptPayment}
            />
            <CaseHistoryBox
                handleOpen={handleOpen}
                setSelectedPayment={setSelectedPayment}
                selectedPayment={selectedPayment}
                retrieveReceipt={retrieveReceipt}
                setRetrieveReceiptPayment={setRetrieveReceiptPayment}
                retrieveReceiptPayment={retrieveReceiptPayment}
            />
        </Box>
    )
}

export default PaymentsView;
